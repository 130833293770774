import React from 'react';
import styled, { css } from 'styled-components';

import { formatDuration } from '../../../util/duration';
import isAfter from 'date-fns/is_after';
import useTime from '../../../util/useTime';

export const AdditionalInfoWrapper = styled.span`
  display: flex;
  margin-top: ${props => ((props.preOpFocused || props.pacuFocused) ? '0.25rem' : '0.5rem')};
  color: ${props => (props.status === 'alert' ? '#ffac00' : '#91c4ff')};
  overflow: hidden;

  justify-content: space-between;

  ${props =>
    props.miniView &&
    css`
      margin-top: 0;
    `}
`;

const Procedure = styled.div`
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;

export const AdditionalInfo = styled.div`
  white-space: nowrap;
  flex: 1 1 0;
  min-width: 0;

  font-size: ${props => (props.size === 'large' ? '1.75rem' : '1.125rem')};
  line-height: 1.1;
  display: flex;

  @media (max-height: 900px) {
    font-size: ${props => (props.size === 'large' ? '1.75rem' : '0.875rem')};
  }

  @media (max-height: 600px) {
    font-size: ${props => (props.size === 'large' ? '1.75rem' : '2vh')};
  }
`;

const InfoRight = styled(AdditionalInfo)`
  display: flex;
  white-space: nowrap;
  flex: 0 1 0;
  justify-content: flex-end;
  min-width: auto;
  opacity: ${props => (props.status === 'alert' ? 'initial' : '0.5')};

  ${props =>
    props.miniView &&
    css`
      justify-content: flex-start;
      font-size: 1rem;
    `}
`;

const PatientSlotAdditionalInfo = ({
  procedure,
  roomName,
  timeEntered,
  status,
  compactView,
  size,
  miniView,
  expectedExitTime,
  preOpFocused,
  pacuFocused,
  showPreOpNote,
  preOpNote,
  showPacuNote,
  pacuNote,
  showRoom,
}) => {
  const currentTime = useTime(5000);
  const isDelayed = isAfter(currentTime, expectedExitTime);
  const duration = formatDuration(timeEntered, currentTime, true);

  const note = showPreOpNote ? preOpNote : showPacuNote ? pacuNote : null;

  return (
    <AdditionalInfoWrapper
      preOpFocused={preOpFocused}
      pacuFocused={pacuFocused}
      status={isDelayed ? 'alert' : null}
      size={size}
      miniView={miniView}
    >
      {!miniView && (
        <AdditionalInfo style={{ minWidth: 0, color: !note ? '#91c4ff' : '#ffac00', opacity: 'none' }} size={size}>
          {!note && procedure && <Procedure>{procedure}</Procedure>}
          {!!note && <Procedure>{note}</Procedure>}
        </AdditionalInfo>
      )}
      <InfoRight miniView={miniView} size={size} status={isDelayed ? 'alert' : null}>
        {timeEntered &&
          (compactView ? roomName || '' : showRoom && roomName ? `In ${roomName} for ${duration}` : `${duration}`)}
      </InfoRight>
    </AdditionalInfoWrapper>
  );
};

export default PatientSlotAdditionalInfo;
