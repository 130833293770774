import { openPdf } from '../../../../../../entities/procedures/pdf/shared';
import { format } from 'date-fns';
import { createPdf } from '../../../../../../../vendor/pdfMake';
import { Room, RoomType } from '../../../../../../../types/Room';
import StaffShift from '../../../../../../../types/StaffShift';

export interface StaffShiftExtended extends StaffShift {
  isProcedure?: boolean;
}

export type RoomWithProcedureFlag = Omit<Room, 'staffShifts'> & { staffShifts: Array<StaffShiftExtended> };

const getOrdinalSuffix = (day: number) => {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const v = day % 100;
  return suffix[(v - 20) % 10] || suffix[v] || suffix[0];
};

const formatDateWithOrdinal = (date: Date) => {
  const day = format(date, 'DD');
  const dayWithSuffix = `${day}${getOrdinalSuffix(parseInt(day, 10))}`;
  const month = format(date, 'MMMM');
  const year = format(date, 'YYYY');
  const weekday = format(date, 'dddd');
  return `${weekday}, ${month} ${dayWithSuffix} ${year}`;
};

const getContent = (hospitalName: string, date: Date, rooms: RoomWithProcedureFlag[], operationRooms: Room[]) => {
  const orRooms = operationRooms.flatMap(e => [
    [
      {
        text: hospitalName || '',
        alignment: 'left',
        fillColor: '#cccccc',
        colSpan: 3,
      },
      '',
      '',
      {
        text: `${e?.name || ''} (${e.procedures?.length || 0} procedures)`,
        alignment: 'center',
        fillColor: '#cccccc',
        colSpan: 3,
      },
      '',
      '',
      {
        text: format(date, 'MM.DD.YYYY'),
        alignment: 'right',
        fillColor: '#cccccc',
        colSpan: 3,
      },
      '',
      '',
    ],
    [
      {
        text: 'Time',
        fillColor: '#cccccc',
      },
      {
        text: 'Patient',
        colSpan: 2,
        fillColor: '#cccccc',
      },
      '',
      {
        text: 'Physician',
        fillColor: '#cccccc',
      },
      {
        text: 'Procedure',
        colSpan: 2,
        fillColor: '#cccccc',
      },
      '',
      {
        text: 'Anesthesia Type',
        fillColor: '#cccccc',
      },
      {
        text: 'Staff Members',
        fillColor: '#cccccc',
        colSpan: 2,
      },
    ],
    ...e.procedures.flatMap((p, i) => [
      [
        {
          text:
            (p?.or || '') +
            '\n' +
            `${p?.startTimeText || ''} to ${p?.endTimeText}` +
            '\n' +
            `${p?.duration || '0'} min`,
          alignment: 'left',
          fillColor: p?.color ? p.color : i % 2 == 1 ? '#eeeeee' : undefined,
          border: [true, true, true, true],
          borderColor: p?.color ? [p.color, p.color, 'black', p.color] : ['black', 'black', 'black', 'black'],
        },
        {
          text:
            `${p?.patientName || ''} (${p?.patientAge || ''} ${p?.patientSex || ''})` +
            '\n' +
            `${p?.patientDateOfBirth || ''}` +
            '\n' +
            `MRN# ${p?.patientHstId || '-'}` +
            '\n' +
            `Phone: ${p?.patientCellPhone || '-'}`,
          colSpan: 2,
          fillColor: i % 2 == 1 ? '#eeeeee' : undefined,
          border: [true, true, true, true],
          borderColor: p?.color ? ['black', p.color, 'black', p.color] : ['black', 'black', 'black', 'black'],
        },
        '',
        {
          text: p?.physician?.name || '',
          fillColor: i % 2 == 1 ? '#eeeeee' : undefined,
          border: [true, true, true, true],
          borderColor: p?.color ? ['black', p.color, 'black', p.color] : ['black', 'black', 'black', 'black'],
        },
        {
          text: p?.procedureType || '',
          colSpan: 2,
          fillColor: i % 2 == 1 ? '#eeeeee' : undefined,
          border: [true, true, true, true],
          borderColor: p?.color ? ['black', p.color, 'black', p.color] : ['black', 'black', 'black', 'black'],
        },
        '',
        {
          text: p?.anesthesiaType || '',
          fillColor: i % 2 == 1 ? '#eeeeee' : undefined,
          border: [true, true, true, true],
          borderColor: p?.color ? ['black', p.color, 'black', p.color] : ['black', 'black', 'black', 'black'],
        },
        {
          text:
            p?.staffShifts
              ?.map(p => {
                const nameParts = (p?.staff?.name || '').split(' ');
                const firstName = nameParts[0] || '';
                const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1][0].toUpperCase() + '.' : '';
                const titleAbbr = p?.staff?.titleAbbr ? ` ${p.staff.titleAbbr}` : '';

                return [
                  {
                    text: `${firstName} ${lastInitial}${titleAbbr}`,
                    bold: true,
                  },
                  {
                    text: p?.from && p?.to ? `: ${p?.from} - ${p?.to}` : '',
                    margin: [5, 0, 0, 0],
                  },
                  {
                    text: p?.additionalText ? `, ${p?.additionalText}` : '',
                    margin: [5, 0, 0, 0],
                  },
                  {
                    text: '\n',
                  },
                ];
              })
              .flat()
              .filter(textObject => textObject.text) || '',
          fillColor: i % 2 == 1 ? '#eeeeee' : undefined,
          border: [true, true, true, true],
          borderColor: p?.color ? ['black', p.color, p.color, p.color] : ['black', 'black', 'black', 'black'],
          colSpan: 2,
        },
      ],
      [
        {
          text: '',
          colSpan: 9,
          border: [false, false, false, false],
          margin: [0, 0.1, 0, 0.1],
        },
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
      ],
    ]),
  ]);

  return {
    stack: [
      {
        table: {
          widths: ['*', '*', '*', '*', '*', '*', '*', '*', '*'],
          body: orRooms,
        },
      },
    ],
  };
};

export const ORSchedulePdfGenerator = (
  hospitalName: string,
  date: Date,
  rooms: RoomWithProcedureFlag[],
  operationRooms: Room[]
) => {
  const docDefinition = {
    pageSize: 'LETTER',
    pageMargins: [40, 40, 40, 40],
    defaultStyle: {
      fontSize: 9,
      lineHeight: 1.4,
    },
    info: { title: `Staff Schedule` },
    header: {
      stack: [
        {
          text: formatDateWithOrdinal(date),
          style: 'header',
          alignment: 'center',
          margin: [0, 10, 0, 10],
        },
      ],
    },
    content: [
      getContent(
        hospitalName,
        date,
        rooms.map(room => ({ ...room, staffShifts: room.staffShifts.filter(staff => !!staff.staff?.name) })),
        operationRooms
      ),
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        alignment: 'center',
        margin: [0, 10, 0, 10],
      },
      subheader: {
        fontSize: 14,
        bold: true,
        alignment: 'justify',
        margin: [0, 0, 0, 10],
      },
      tableHeaderRoomTitle: {
        bold: true,
        fontSize: 16,
        color: 'black',
        fillColor: '#CCCCCC',
        margin: [2, 0, 0, -10],
      },
    },
  };

  openPdf(createPdf(docDefinition), `OR_Schedule${format(date, 'MM_dd_yyyy_HH_mm_ss')}`);
};
