import { gql } from '@apollo/client';

import { fragments as patientFragments } from './patients';
import { fragments as staffFragments } from './staff';
import { fragments as bookingFragments } from './bookings';
import { fragments as anesthesiologistFragments } from './anesthesiologists';

export const fragments = {
  m: gql`
    fragment RoomDataM on Room {
      id
      name
    }
  `,
  s: gql`
    fragment RoomDataS on Room {
      id
      name
      type
      capacity
      availability
      isAlphabeticBedOrder
      isHideInternalScreen
      isHideScheduleScreen
      airFiltrationEndsAt
      airFiltrationWarningOn
      airFiltrationDurationMin
      cleaningStartedAt
      order
    }
  `,
  all: gql`
    fragment RoomData on Room {
      id
      name
      type
      capacity
      availability
      isAlphabeticBedOrder
      isHideInternalScreen
      isHideScheduleScreen
      airFiltrationEndsAt
      airFiltrationWarningOn
      airFiltrationDurationMin
      cleaningStartedAt
      order
    }
  `,
};

export const list = gql`
  query rooms {
    rooms {
      ...RoomDataS
    }
  }
  ${fragments.s}
`;

export const listNursing = gql`
  subscription rooms {
    rooms {
      ...RoomDataS
      patient {
        id
      }
    }
  }
  ${fragments.s}
`;

export const notUtilityRooms = gql`
  query notUtilityRooms {
    rooms: notUtilityRooms {
      ...RoomDataS
    }
  }
  ${fragments.s}
`;

export const item = gql`
  query room($id: Long!) {
    room(id: $id) {
      ...RoomData
    }
  }
  ${fragments.all}
`;

export const create = gql`
  mutation createRoom(
    $name: String!
    $type: RoomType!
    $capacity: Int
    $availability: Long
    $airFiltrationDurationMin: Int
    $isAlphabeticBedOrder: Boolean
    $isHideInternalScreen: Boolean
    $isHideScheduleScreen: Boolean
    $order: Int
  ) {
    createRoom(
      name: $name
      type: $type
      capacity: $capacity
      availability: $availability
      airFiltrationDurationMin: $airFiltrationDurationMin
      isAlphabeticBedOrder: $isAlphabeticBedOrder
      isHideInternalScreen: $isHideInternalScreen
      isHideScheduleScreen: $isHideScheduleScreen
      order: $order
    ) {
      ...RoomDataS
    }
  }
  ${fragments.s}
`;

export const update = gql`
  mutation editRoom(
    $id: Long!
    $name: String!
    $type: RoomType!
    $capacity: Int
    $availability: Long
    $airFiltrationDurationMin: Int
    $isAlphabeticBedOrder: Boolean
    $isHideInternalScreen: Boolean
    $isHideScheduleScreen: Boolean
    $order: Int
  ) {
    editRoom(
      id: $id
      name: $name
      type: $type
      capacity: $capacity
      availability: $availability
      airFiltrationDurationMin: $airFiltrationDurationMin
      isAlphabeticBedOrder: $isAlphabeticBedOrder
      isHideInternalScreen: $isHideInternalScreen
      isHideScheduleScreen: $isHideScheduleScreen
      order: $order
    ) {
      ...RoomDataS
    }
  }
  ${fragments.s}
`;

export const remove = gql`
  mutation deleteRoom($id: Long!) {
    deleteRoom(id: $id)
  }
`;

export const addAnesthesiologistShiftRoom = gql`
  mutation addAnesthesiologistShiftRoom($id: Long!, $date: Date!, $anesthesiologistId: Long!) {
    addAnesthesiologistShiftRoom(roomId: $id, date: $date, anesthesiologistId: $anesthesiologistId)
  }
`;

export const removeAnesthesiologistShiftRoom = gql`
  mutation removeAnesthesiologistShiftRoom($id: Long!) {
    removeAnesthesiologistShiftRoom(id: $id)
  }
`;

export const listSubscription = gql`
  subscription {
    internalMonitor {
      id
      name
      type
      capacity
      awaitingCleanup
      airFiltrationEndsAt
      airFiltrationWarningOn
      airFiltrationDurationMin
      cleaningStartedAt
      isHideInternalScreen
      order
      procedure {
        id
        startTime
        duration
        orId
        patientId
        isCanceled
        color
        patient {
          dischargedAt
          room {
            type
          }
        }
      }
      patient {
        id
        name
        type
        procedureType {
          id
          name
        }
        physician {
          id
          name
          notificationNumbers
          shouldSendNotification
        }
        icon
        color
        status
        createdAt
        changedRoomAt
        called
        ...PatientLog
      }
      patients {
        id
        name
        type
        bed
        priority
        preOpNote
        pacuNote
        blockNerveFollow
        procedureType {
          id
          name
          expectedPreOpDuration
          expectedDuration
          expectedPacuDuration
        }
        physician {
          id
          name
        }
        caretakerMessages {
          phoneNumber
          content
        }
        createdAt
        changedRoomAt
        status
        called
        ...PatientLog
      }
    }
  }
  ${patientFragments.patientLog}
`;

export const opreationRoomSubscription = gql`
  subscription tabletApp($roomId: Long!) {
    tabletApp(roomId: $roomId) {
      id
      name
      type
      airFiltrationEndsAt
      airFiltrationWarningOn
      airFiltrationDurationMin
      cleaningStartedAt
      order
      patient {
        id
        name
        type
        procedureType {
          id
          name
        }
        physician {
          id
          name
          notificationNumbers
          shouldSendNotification
        }
        icon
        color
        caretakerConsent
        notificationNumbers
        caretakerThread {
          id
          hasUnreadMessages
        }
        status
        createdAt
        changedRoomAt
        procedure {
          id
          anesthesiologist {
            id
            name
          }
          anesthesiaType
        }
        ...PatientLog
      }
      patients {
        id
        name
        type
        procedureType {
          id
          name
        }
        physician {
          id
          name
        }
        icon
        color
        status
        createdAt
        changedRoomAt
      }
      awaitingCleanup
    }
  }
  ${patientFragments.patientLog}
`;

export const scheduleSubscription = gql`
  subscription {
    scheduleMonitor {
      ...RoomDataS
    }
  }
  ${fragments.s}
`;

export const startTimeout = gql`
  mutation startTimeout($id: Long!) {
    startTimeout(id: $id)
  }
`;

export const patientReadyInOR = gql`
  mutation patientReadyInOR($id: Long!) {
    patientReadyInOR(id: $id)
  }
`;

export const startProcedure = gql`
  mutation startProcedure($id: Long!) {
    startProcedure(id: $id)
  }
`;

export const startClosing = gql`
  mutation startClosing($id: Long!) {
    startClosing(id: $id)
  }
`;

export const startDressingOn = gql`
  mutation startDressingOn($id: Long!) {
    startDressingOn(id: $id)
  }
`;

export const completeCleaning = gql`
  mutation completeCleaning($id: Long!) {
    completeCleaning(id: $id)
  }
`;

export const skipCleaning = gql`
  mutation skipCleaning($id: Long!) {
    skipCleaning(id: $id)
  }
`;

export const markAsComplete = gql`
  mutation markAsComplete($patientId: Long!) {
    markAsComplete(patientId: $patientId)
  }
`;

export const setPatientStatus = gql`
  mutation setPatientStatus($id: Long!, $status: PatientStatus!) {
    setPatientStatus(id: $id, status: $status)
  }
`;

export const roomsForGateway = gql`
  query roomsForGateway($hospitalId: Long) {
    rooms: roomsForGateway(hospitalId: $hospitalId) {
      ...RoomDataM
    }
  }
  ${fragments.m}
`;

export const roomsListWithStaffShiftSubscription = gql`
  subscription rooms($date: Date!) {
    rooms {
      ...RoomDataS
      staffShifts(date: $date) {
        ...StaffShiftData
      }
      bookings(date: $date) {
        ...BookingData
      }
      anesthesiologistShifts(date: $date) {
        ...AnesthesiologistShiftRoomData
      }
    }
  }
  ${fragments.s}
  ${staffFragments.shift}
  ${bookingFragments.all}
  ${anesthesiologistFragments.shift}
`;

export const restartAirFiltration = gql`
  mutation restartAirFiltration($id: Long!) {
    restartAirFiltration(id: $id)
  }
`;

export const roomSubscription = gql`
  subscription tabletApp($roomId: Long!) {
    room: tabletApp(roomId: $roomId) {
      ...RoomDataS
    }
  }
  ${fragments.s}
`;

export const scheduleUserRoomShifts = gql`
  subscription scheduleUserRoomShifts($scheduleUserId: Long!, $scheduleUserType: String!, $offset: Int!, $limit: Int!) {
    scheduleUserRoomShifts(
      scheduleUserId: $scheduleUserId
      scheduleUserType: $scheduleUserType
      offset: $offset
      limit: $limit
    ) {
      totalCount
      entities {
        ...StaffShiftRoomData
      }
      hasNextPage
    }
  }
  ${staffFragments.roomShift}
`;

export const setRoomShiftState = gql`
  mutation setRoomShiftState($shiftId: Long!, $scheduleUserType: String!, $scheduleUserId: Long!, $state: ShiftState!) {
    setRoomShiftState(
      shiftId: $shiftId
      scheduleUserType: $scheduleUserType
      scheduleUserId: $scheduleUserId
      state: $state
    )
  }
`;

export default {
  list,
  item,
  create,
  update,
  remove,
  listSubscription,
};
