import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 2,
    overflow: 'hidden',
    boxSizing: 'border-box',
    cursor: 'pointer',
    userSelect: 'none',
    marginBottom: '0.5em',
    transition: 'transform 100ms ease-in-out, background-color 150ms ease-in-out',

    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  pulsate: {
    animation: `$pulsate 0.75s ease-in-out alternate infinite`,
  },
  hasError: {
    animation: `$pulsate 0.75s ease-in-out alternate infinite`,
  },
  customBorder: {
    border: `4px solid`,
  },
  dpuFromOrBorder: {
    border: '1px solid purple',
  },
  helpBorder: {
    border: '3px solid red',
  },
  disabled: {
    opacity: 0.3,
    pointerEvents: 'none',
    border: 'none',
    animation: 'none',

    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  '@keyframes pulsate': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0.75,
    },
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: '3em',
  },
  cheboxContainer: {
    backgroundColor: alpha(theme.palette.text.primary, 0.1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 1,
  },
  patientAndPersonnel: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
  },
}));

export default useStyles;
